/**
 * 路由
 */
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

/*** 引入路由界面 ***/  
import Login from '../components/login/Login.js';
import Pfind from '../components/pfind/Pfind.js'
/*** 引入路由界面 ***/ 

const BasicRouter = () => (
  <Router>
      <Routes>
          <Route exact path="/" element={<Login/>}/> 
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/pfind/*" element={<Pfind/>}/>  
      </Routes>     
  </Router>
)

export default BasicRouter;