/**
 * 找回密码 - 主页
 */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import "antd/dist/antd.css";
import CONFIG from "../../include/Config.js";
import { Row,Col,Button,Input,message } from "antd";
import { InfoCircleOutlined,} from "@ant-design/icons";
import { UjoinParams } from "../../include/FunHelper.js";
import PfindEntity from "./PfindEntity.js";

import Logo from "../../images/update_logo.jpg";

/***
 * 获取用户
 */
class UserName extends Component{
    constructor(props){
        super(props);
        this.state = {
            username:"",
            msg: "",
            loading: false
        }
    }

    changUsername = (e) =>{
        this.setState({ 
            username: e.target.value,
            msg: "",
            loading: false 
        });
    }

    /***
     * 验证用户名
     */
     verifyUsername = () =>{
        this.setState({ loading: true });

        const _this = this
        const params = {
            "key": this.state.username
        }
        $.ajax({
            url: CONFIG.sealApi + "user/verify/username" + UjoinParams(params),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "OPTIONS",success:function(data){
                if(data.res === global.ENUM_RES.success){
                    if(data.cnt>0){
                        //  用户名存在
                        _this.props.renderEntity(_this.state.username)
                    }else{
                        _this.setState({ msg: "用户不存在！" });
                    }
                }else{
                    message.error(data.msg, 1);
                }
            }
        })
    }

    render(){
        return(
            <div>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={14} offset={5}>
                        <InfoCircleOutlined /><label>&nbsp;请输入你需要找回登录密码的账户名</label>
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={1} offset={5}>
                        <label>登录名：</label>
                    </Col>
                    <Col span={3}>
                        <Input 
                            value={this.state.username}
                            onChange={this.changUsername}
                            placeholder="请输入登录名" 
                            style={{borderRadius:"0" }}
                        />
                    </Col>
                    <Col span={4}><span style={{ marginLeft:"10px", lineHeight:"30px" ,color:"red" }}>{this.state.msg}</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: "0px" }}>
                    <Col span={2} offset={5}>
                        <Button 
                            block={true} 
                            type="primary"
                            style={{ backgroundColor:"#EF6730",borderRadius:"0",border:"0",marginTop:"25px" }}
                            disabled={this.state.loading}
                            onClick={() =>{
                                this.verifyUsername();
                            }}>马上验证
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
 }

export default class Pfind extends Component{
    constructor(props){
        super(props);
        this.state = {
            username: ""
        }
    }

    componentDidMount(){
        /***
         * 检验地址的合法性
         * 1.检验时间戳的合法性，相差时间不能超过 Config 中设置的 maxAge 值
         * 2.检验参数的合法性
        */
        let rts = true;
        let paras = window.location.pathname;
        while(true){
            if( !paras || paras.split("&").length < 2){
                rts = false;
                break;
            }
            let ts = paras.split("=");
            if(ts.length < 2){
                rts = false;
                break;
            }
            let dt = Date.parse(new Date()) - ts[1] * CONFIG.tDivisor;
            if( dt < 0 || dt > CONFIG.maxAge * 1000){
                rts = false;
                break;
            }
            break;
        }
        if(!rts){
            this.props.history.push("/login");
        }else{
            this.renderUserName();
        }
    }

    renderUserName(){
        ReactDOM.render(
            <UserName renderEntity={this.renderEntity}/>,
            document.getElementById("pfind-entity-id")
        );
    }

    renderEntity(username){
        ReactDOM.render(
            <PfindEntity username={username} />,
            document.getElementById("pfind-entity-id")
        );
    }

    render(){
        return(
            <div>
                <div>
                    <Row  style={{ backgroundColor: "#2A8BD5" }}>
                        <Col span={3} offset={5}>
                            <a href={CONFIG.homePage}>
                                <img src={Logo} alt="umxwe" style={{ height: "100px"}}/>
                            </a>
                        </Col>
                        <Col span={8} style={{ height: "100px" }}>
                            <h3 style={{ color:"#FFFFFF",lineHeight:"100px" }}>找回密码</h3>
                        </Col>
                        <Col span={1} style={{ textAlign:"center" }}>
                            <a style={{ color:"#FFFFFF",lineHeight:"100px" }} href="/login">登录</a>
                            <span style={{ color:"#FFFFFF",lineHeight:"100px",float:"right" }}>|</span>
                        </Col>
                        <Col span={1} style={{ textAlign:"center" }}>
                            <a style={{ color:"#FFFFFF",lineHeight:"100px" }} href={CONFIG.homePage}>首页</a>
                            <span style={{ color:"#FFFFFF",lineHeight:"100px",float:"right" }}>|</span>
                        </Col>
                        <Col span={1} style={{ textAlign:"center" }}>
                            <a style={{ color:"#FFFFFF",lineHeight:"100px",pointerEvents: "none" }} href="/">帮助</a>
                        </Col>
                    </Row>
                </div>
                <div id="pfind-entity-id"></div>
            </div>
        );
    }
}