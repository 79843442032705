/**
 * 找回密码 - 实体
 */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { Row,Col,Steps,Button,Input,Progress } from "antd";
import "antd/dist/antd.css";
import {
    CheckCircleOutlined,
    EditOutlined,
    CheckOutlined,
    SolutionOutlined,
} from "@ant-design/icons";
import CONFIG from "../../include/Config.js";
import {UencryptoStr, UjoinParams} from "../../include/FunHelper.js";
const { Step } = Steps;

/***
 * 手机验证
 */
class PhoneS1 extends Component{
    constructor(props){
        super(props);
        this.state = {
            phone: "",
            vCode:"",
            pMsg: "*",
            msg: "",
            vMsg: "",
            isGainVCode: 0,
            vTime: 0,
            bTitle: "获取验证码",
            loading: false
        }
    }

    changPhone = (e) =>{
        this.setState({ 
            phone: e.target.value,
            pMsg:"*",
            loading: false 
        });
    }

    changVCode = (e) =>{
        this.setState({ 
            vCode: e.target.value,
            msg:"",
            loading: false 
        });
    }

    changeVTime = (t) =>{ this.setState({ vTime: t }); }

    /***
     * 验证用户手机
     */
     verifyPhone(){
        if(!/^1[34578]\d{9}$/.exec(this.state.phone)){
            this.setState({ pMsg: "手机号格式错误！" });
            return;
        }
        const _this = this;
        const params = {
            "username": _this.props.username,
            "phone": this.state.phone
        }
        $.ajax({
            url: CONFIG.sealApi + "user/verify/phone" + UjoinParams(params),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "OPTIONS",success:function(data){
                if(data.res === global.ENUM_RES.success){
                    if(data.cnt > 0){
                        _this.userGainVCode();
                    }else{
                        _this.setState({ pMsg: "手机号不匹配！"});
                    }
                }
            }
        })
    }

    /***
     * 获取验证码
     */
    userGainVCode(){
        this.setState({ vTime: 60 });
        const _this = this;
        const params = {
            "mode": "phone",
            "phone": this.state.phone,
            "username": this.props.username
        }
        $.ajax({
            url: CONFIG.sealApi + "user/gain/vcode" + UjoinParams(params),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "GET",success:function(data){
                if(data.res === global.ENUM_RES.success){
                    _this.setState({ isGainVCode: 1 });
                }else{
                    _this.setState({ vMsg:data.msg });
                }
            }
        })
    }

    /***
     * 通过验证码验证身份
     */
    verifyVCode(){
        var rex = /(^[0-9]{6})/.exec(this.state.vCode);
        if(rex == null || rex[0] !== this.state.vCode){
            this.setState({ msg: "验证码格式错误！"});
            return;
        }
        const _this = this;
        this.setState({ loading: true });
        const params = {
            "vCode": this.state.vCode,
            "username": this.props.username
        }
        $.ajax({
            url: CONFIG.sealApi + "user/verify/vcode" + UjoinParams(params),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "OPTIONS",success:function(data){
                _this.setState({
                    res: data.res,
                    msg: data.msg
                });
                if(data.res === global.ENUM_RES.success){
                    _this.props.changeVfp(data.vfp);
                    _this.props.changeState(2);
                }
            }
        })
    }

    render(){
        return(
            <div>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={14} offset={5}>
                        <label>手机验证账户 <span style={{ color:"#EF6730" }}>{this.props.username}</span> 为确保是您本人操作，请完成以下验证：</label>
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={2} offset={5}>
                        <label>手机号码：</label>
                    </Col>
                    <Col span={3}>
                        <Input 
                            value={this.state.phone}
                            onChange={this.changPhone}
                            placeholder="请输入注册手机号" 
                            style={{borderRadius:"0",width:"100%" }}
                        />
                    </Col>
                    <Col span={4} style={{ lineHeight: 2.2 }}><span style={{ color: "red", marginLeft: "10px" }}>{this.state.pMsg}</span></Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={2} offset={5}>
                        <label>验证码：</label>
                    </Col>
                    <Col span={3}>
                        <Input 
                            value={this.state.vCode}
                            onChange={this.changVCode}
                            placeholder="请输入验证码" 
                            style={{borderRadius:"0",width:"100%" }}
                        />
                    </Col>
                    <Col span={4} offset={1}>
                        <Button 
                            block={true} 
                            type="primary"
                            style={{ backgroundColor:"#EF6730",borderRadius:"0",border:"0"}}
                            disabled={this.state.vTime > 0?true:false}
                            onClick={() =>{
                                this.verifyPhone();
                            }}><BTitle vTime={this.state.vTime} changeVTime={this.changeVTime}/>
                        </Button>
                    </Col>
                    <Col span={4} style={{ lineHeight: 2.2 }}><span style={{ color: "red", marginLeft: "10px" }}>{this.state.vMsg}</span></Col>
                </Row>
                <Row>
                    <Col span={this.state.isGainVCode < 1?0:12} offset={7}>
                        <label style={{ fontSize:"12px",color:"#B2B2B2" }}>
                            <CheckCircleOutlined style={{ color:"green" }} /> 验证码已发送到您的手机，15分钟内输入有效，验证码等同于密码，打死也不能告诉别人哦！
                        </label>
                    </Col>
                </Row>
                <Row style={{ marginTop: "25px" }}>
                    <Col span={2} offset={5}>
                        <Button 
                            block={true} 
                            type="primary"
                            style={{ backgroundColor:"#2A8BD5",borderRadius:"0",border:"0"}}
                            disabled={this.state.loading}
                            onClick={() =>{
                                this.verifyVCode();
                            }}>马上验证
                        </Button>
                    </Col>
                    <Col span={4}><span style={{ color: "red", marginLeft:"10px",lineHeight:"30px" }}>{this.state.msg}</span></Col>
                </Row>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={18} offset={5} style={{ fontSize: "12px" }}>
                        <p style={{ fontSize: "16px",fontWeight: "bold" }}>没收到短信验证码？</p>
                        <p>1、网络通讯异常可能会造成短信丢失，请重新获取或稍后再试。</p>
                        <p>2、请核实手机是否已欠费停机，或者屏蔽了系统短信。</p>
                        <p>3、如果手机已丢失或停用， 请选择其他验证方式 。</p>
                        <p>4、您也可以尝试将SIM卡移动到另一部手机，然后重试。</p>
                    </Col>
                </Row>
            </div>
        )
    }
}

/***
 * 邮箱验证
 */
class EmailS1 extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: "",
            vCode:"",
            eMsg: "*",
            msg: "",
            vMsg: "",
            isGainVCode: 0,
            vTime: 0,
            bTitle: "获取验证码",
            loading: false
        }
    }

    changEmail = (e) =>{
        this.setState({ 
            email: e.target.value,
            eMsg:"*",
            loading: false 
        });
    }

    changVCode = (e) =>{
        this.setState({ 
            vCode: e.target.value,
            msg:"",
            loading: false 
        });
    }

    changeVTime = (t) =>{ this.setState({ vTime: t }); }

    /***
     * 验证用户邮箱
     */
     verifyEmail(){
        if(!/^([a-zA-Z]|[0-9])(\w|\\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.exec(this.state.email)){
            this.setState({ eMsg: "邮箱格式错误！" });
            return;
        }
        const _this = this;
        const params = {
            "username": _this.props.username,
            "email": this.state.email
        }
        $.ajax({
            url: CONFIG.sealApi + "user/verify/email" + UjoinParams(params),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "OPTIONS",success:function(data){
                if(data.res === global.ENUM_RES.success){
                    if(data.cnt > 0){
                        _this.userGainVCode();
                    }else{
                        _this.setState({ pMsg: "邮箱不匹配！"});
                    }
                }
            }
        })
    }

    /***
     * 获取验证码
     */
    userGainVCode(){
        this.setState({ vTime: 60 });
        const _this = this;
        const params = {
            "mode": "email",
            "email": this.state.email,
            "username": this.props.username
        }
        $.ajax({
            url: CONFIG.sealApi + "user/gain/vcode" + UjoinParams(params),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "GET",success:function(data){
                if(data.res === global.ENUM_RES.success){
                    _this.setState({ isGainVCode: 1 });
                }else{
                    _this.setState({ vMsg:data.msg });
                }
            }
        })
    }

    /***
     * 通过验证码验证身份
     */
    verifyVCode(){
        var rex = /(^[0-9]{6})/.exec(this.state.vCode);
        if(rex == null || rex[0] !== this.state.vCode){
            this.setState({ msg: "验证码格式错误！" });
            return;
        }
        const _this = this;
        this.setState({ loading: true });
        const params = {
            "vCode": this.state.vCode,
            "username": _this.props.username
        }
        $.ajax({
            url: CONFIG.sealApi + "user/verify/vcode" + UjoinParams(params),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "OPTIONS",success:function(data){
                _this.setState({
                    res: data.res,
                    msg: data.msg
                });
                if(data.res === global.ENUM_RES.success){
                    _this.props.changeVfp(data.vfp);
                    _this.props.changeState(2);
                }
            }
        })
    }

    render(){
        return(
            <div>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={14} offset={5}>
                        <label>邮箱验证账户 <span style={{ color:"#EF6730" }}>{this.props.username}</span> 为确保是您本人操作，请完成以下验证：</label>
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={2} offset={5}>
                        <label>邮箱：</label>
                    </Col>
                    <Col span={4}>
                        <Input 
                            value={this.state.email}
                            onChange={this.changEmail}
                            placeholder="请输入注册邮箱" 
                            style={{borderRadius:"0",width:"100%" }}
                        />
                    </Col>
                    <Col span={4} style={{ lineHeight: 2.2 }}><span style={{ color: "red", marginLeft: "10px" }}>{this.state.eMsg}</span></Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={2} offset={5}>
                        <label>验证码：</label>
                    </Col>
                    <Col span={4}>
                        <Input 
                            value={this.state.vCode}
                            onChange={this.changVCode}
                            placeholder="请输入验证码" 
                            style={{borderRadius:"0",width:"100%" }}
                        />
                    </Col>
                    <Col span={4} offset={1}>
                        <Button 
                            block={true} 
                            type="primary"
                            style={{ backgroundColor:"#EF6730",borderRadius:"0",border:"0"}}
                            disabled={this.state.vTime > 0?true:false}
                            onClick={() =>{
                                this.verifyEmail();
                            }}><BTitle vTime={this.state.vTime} changeVTime={this.changeVTime}/>
                        </Button>
                    </Col>
                    <Col span={4} style={{ lineHeight: 2.2 }}><span style={{ color: "red", marginLeft: "10px" }}>{this.state.vMsg}</span></Col>
                </Row>
                <Row>
                    <Col span={this.state.isGainVCode < 1?0:12} offset={7}>
                        <label style={{ fontSize:"12px",color:"#B2B2B2" }}>
                            <CheckCircleOutlined style={{ color:"green" }} /> 验证码已发送到您的邮箱，15分钟内输入有效，验证码等同于密码，打死也不能告诉别人哦！
                        </label>
                    </Col>
                </Row>
                <Row style={{ marginTop: "25px" }}>
                    <Col span={2} offset={5}>
                        <Button 
                            block={true} 
                            type="primary"
                            style={{ backgroundColor:"#2A8BD5",borderRadius:"0",border:"0"}}
                            disabled={this.state.loading}
                            onClick={() =>{
                                this.verifyVCode();
                            }}>马上验证
                        </Button>
                    </Col>
                    <Col span={4}><span style={{ color: "red", marginLeft:"10px",lineHeight:"30px" }}>{this.state.msg}</span></Col>
                </Row>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={18} offset={5} style={{ fontSize: "12px" }}>
                        <p style={{ fontSize: "16px",fontWeight: "bold" }}>没收到邮件验证码？</p>
                        <p>1、网络异常可能会造成邮件丢失，请重新获取或稍后再试。</p>
                        <p>2、检查邮件收信规则设置，检查收信条件是否设置不当。</p>
                        <p>3、检查反垃圾设置，黑名单中是否误加入了正常邮箱地址 。</p>
                        <p>4、或者在垃圾邮件中查找看看。</p>
                    </Col>
                </Row>
            </div>
        )
    }
}

/***
 * 控制获取验证码按钮文字显示
 */
class BTitle extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: "获取验证码"
        }
    }

    componentWillReceiveProps(nextProps){
        const _this = this;
        if(nextProps.vTime > 0){
            setTimeout(function(){
                _this.props.changeVTime(nextProps.vTime - 1);
            },1000);
            _this.setState({ title: nextProps.vTime - 1 + "s 后，可重新获取验证码" });
        }
        if(nextProps.vTime < 1){
            _this.setState({ title: "获取验证码" }); 
        }
    }

    render(){
        return(
            <span>{this.state.title}</span>
        )
    }
}

/***
 * 修改密码
 */
class PasswordS2 extends Component{
    constructor(props){
        super(props);
        this.state = {
            password:"",
            vPassword: "",
            msg: "",
            loading: false,
            pStrength: 0,
            c1: "#BFBFBF",
            c2: "#BFBFBF",
            c3: "#BFBFBF"
        }
    }

    getPasswordType(value){
        var s = 0;
        // 是否包含数字
        if(/([0-9])/.exec(value)){
            s += 1;
        }
        //  是否包含小写字母
        if(/([a-z])/.exec(value)){
            s += 1;
        }
        //  是否包含大写字母
        if(/([A-Z])/.exec(value)){
            s += 1;
        }
        //  是否包含标点符号
        if(/([^0-9a-zA-Z])/.exec(value)){
            s += 1;
        }
        return s;
    }

    verifyPasswordStrength(value){
        /***
         * 验证密码强度
         * 1、密码必须在 6~20 个字符之间
         * 2、只能包含大小写字母、数字、下滑线及标点符号（空格除外）
         * 3、大写字母、小写字母、数字及标点符号至少包含 2 种及以上
         * 
         * 密码强弱判断
         * 通过权重判断
         * 1、长度 < 8 w += 1
         * 2、长度 >= 8 w += 2
         * 3、2 种类型 w += 1
         * 4、3 种类型 w += 2
         * 5、4 种类型 w += 3
         * 6 w <= 2 pStrength=1
         * 7 w > 2 && w <=4 pStrength=2
         * 8 w > 4 pStrength=3
         */

        var w = 0;
        if(value.length >= 8){
            w += 1;
        }

        var s = this.getPasswordType(value);
        if(s < 2){
            w += 1;
        }else if(s > 3){
            w += 3;
        }else{
            w += 2;
        }

        var pStrength = 1;
        if(w > 3){
            pStrength = 3;
        }else if(w > 1 && w < 4){
            pStrength = 2;
        }

        this.setState({ pStrength: pStrength });
    }

    changPassword = (e) =>{
        this.setState({ 
            loading: false,
            msg:"",
            password: e.target.value.replace(" ","")
        });
        if(e.target.value.replace(" ","").length >= 6){
            this.verifyPasswordStrength(e.target.value.replace(" ",""));
        }
    }

    changVPassword = (e) =>{
        this.setState({ 
            loading: false,
            msq:"",
            vPassword: e.target.value 
        });
    }

    updatePassword(){
        this.setState({ loading: true });
        var rex = /([a-zA-Z0-9~!@#$%^&*()-_+=,<.>]{6,20})/.exec(this.state.password)
        if(this.state.password.length < 6 
        || this.state.password.length > 20 
        || this.getPasswordType(this.state.password) < 2
        || rex == null || rex[0] !== this.state.password){
            this.setState({ msg: "密码格式错误！" });
            return;
        }

        if(this.state.password !== this.state.vPassword){
            this.setState({ msg: "两次输入密码不一致！" });return;
        }

        const _this = this;

        $.ajax({
            url: CONFIG.sealApi + "user/update/password",
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            type: "PUT",
            dataType: "JSON",
            data:JSON.stringify({
                "vfp": this.props.vfp,
                "password": UencryptoStr(this.state.password),
                "username": _this.props.username
            }),success:function(data){
                _this.setState({
                    res: data.res,
                    msg: data.msg
                });
                if(data.res === global.ENUM_RES.success){
                    _this.props.changeState(3);
                }else{
                    _this.setState({ loading: false });
                }
            }
        })
    }

    componentDidUpdate(){
        var c1 = "#BFBFBF";
        var c2 = "#BFBFBF";
        var c3 = "#BFBFBF";
        switch(this.state.pStrength){
            case 1:
                c1 = "red";
                break;
            case 2:
                c1 = "#EF6730";
                c2 = "#EF6730";
                break;
            case 3:
                c1 = "green";
                c2 = "green";
                c3 = "green";
                break;
            default:
                break;
        }
        if(this.state.c1 !== c1 || this.state.c2 !== c2 || this.state.c3 !== c3){
            this.setState({
                c1: c1,
                c2: c2,
                c3: c3
            });
        }
    }

    render(){
        return(
            <div>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={14} offset={5}>
                        <label>修改账户 <span style={{ color:"#EF6730" }}>{this.props.username}</span> 密码，请完成修改。</label>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px",fontSize:"12px" }}>
                    <Col span={14} offset={5}>
                        <p>1、密码必须在 6~20 个字符之间</p>
                        <p>2、只能包含大小写字母、数字、下滑线及标点符号（空格除外）</p>
                        <p>3、大写字母、小写字母、数字及标点符号至少包含 2 种及以上</p>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col span={3} offset={5}>
                        <Input 
                            value={this.state.password}
                            onChange={this.changPassword}
                            type={"password"}
                            placeholder="请输入新密码..." 
                            style={{borderRadius:"0" }}
                        />
                    </Col>
                    <Col span={4} style={{ marginLeft:"10px", lineHeight:"30px" ,color: "red" }}>
                            {
                                this.state.password === ""?(<span>*</span>):(
                                    <div>
                                        <Progress strokeColor={this.state.c1} style={{ float:"left",width:"30px" }} showInfo={false} strokeLinecap="square" percent={100} />
                                        <Progress strokeColor={this.state.c2} style={{ float:"left",marginLeft:"5px",width:"30px" }} showInfo={false} strokeLinecap="square" percent={100} />
                                        <Progress strokeColor={this.state.c3} style={{ float:"left",marginLeft:"5px",width:"30px" }} showInfo={false} strokeLinecap="square" percent={100} />
                                    </div>
                                )
                            }
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={3} offset={5}>
                        <Input 
                            value={this.state.vPassword}
                            onChange={this.changVPassword}
                            type={"password"}
                            placeholder="请再次输入新密码..." 
                            style={{borderRadius:"0" }}
                        />
                    </Col>
                    <Col span={4} style={{ marginLeft:"10px", lineHeight:"30px" ,color: "red" }}><span>*</span></Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={2} offset={5}>
                        <Button 
                            block={true} 
                            type="primary"
                            style={{ backgroundColor:"#EF6730",borderRadius:"0",border:"0" }}
                            disabled={this.state.loading}
                            onClick={() =>{
                                this.updatePassword();
                            }}>马上修改</Button>
                    </Col>
                    <Col span={4} style={{ marginLeft:"10px", lineHeight:"30px" ,color: "red" }}><span>{this.state.msg}</span></Col>
                </Row>
            </div>
        )
    }
}

/***
 * 完成修改
 */
class UpdateS3 extends Component{
    render(){
        return(
            <div>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={5} offset={5}>
                        <label>账户 <span style={{ color:"#EF6730" }}>{this.props.username}</span> {this.props.title}已完成重置，是否？</label>
                    </Col>
                    <Col span={2}>
                        <Button 
                            block={true} 
                            type="primary"
                            style={{ backgroundColor:"#EF6730",borderRadius:"0",border:"0" }}
                            onClick={() =>{
                                window.location.href = "/";
                            }}>重新登录</Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

class Phone extends Component{
    constructor(props){
        super(props);
        this.state = {
            s: this.props.s,
            msg: "*",
            vfp: ""
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.state.s !== nextProps.s){
            switch(nextProps.s){
                case 1:
                    this.renderPhoneS1();
                    break;
                case 2:
                    this.renderUpdateS2();
                    break;
                case 3:
                    this.renderUpdateS3();
                    break;
                default:
                    break;
            }
            this.setState({ s:nextProps.s });
        }
    }

    componentDidMount(){
        this.renderPhoneS1(this.props.phone);
    }

    changeVfp = (value) =>{
        this.setState({ vfp: value });
    }

    renderPhoneS1(phone){
        ReactDOM.render(
            <PhoneS1 
                username={this.props.username}
                changeState={this.props.changeState}
                phone={phone}
                changeVfp={this.changeVfp}
                sealApi={this.props.sealApi} />,
            document.getElementById("update-content-id")
        );
    }

    renderUpdateS2(){
        ReactDOM.render(
            <PasswordS2
                username={this.props.username}
                changeState={this.props.changeState}
                vfp={this.state.vfp}
                sealApi={this.props.sealApi} />,
            document.getElementById("update-content-id")
        );
    }

    renderUpdateS3(){
        ReactDOM.render(
            <UpdateS3 
                uid={this.props.uid}
                username={this.props.username}
                changeState={this.props.changeState}
                title={this.props.title} 
                loginOut={this.props.loginOut}/>,
            document.getElementById("update-content-id")
        );
    }

    render(){
        return(
            <div id="update-content-id"></div>
        )
    }
}

class Email extends Component{
    constructor(props){
        super(props);
        this.state = {
            s: this.props.s,
            msg: "*",
            vfp: ""
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.state.s !== nextProps.s){
            switch(nextProps.s){
                case 1:
                    this.renderEmailS1();
                    break;
                case 2:
                    this.renderUpdateS2();
                    break;
                case 3:
                    this.renderUpdateS3();
                    break;
                default:
                    break;
            }
            this.setState({ s:nextProps.s });
        }
    }

    componentDidMount(){
        this.renderEmailS1(this.props.email);
    }

    changeVfp = (value) =>{
        this.setState({ vfp: value });
    }

    renderEmailS1(email){
        ReactDOM.render(
            <EmailS1 
                username={this.props.username}
                changeState={this.props.changeState}
                email={email}
                changeVfp={this.changeVfp}
                sealApi={this.props.sealApi}/>,
            document.getElementById("update-content-id")
        );
    }

    renderUpdateS2(){
        ReactDOM.render(
            <PasswordS2 
                username={this.props.username}
                changeState={this.props.changeState}
                vfp={this.state.vfp}
                sealApi={this.props.sealApi} />,
            document.getElementById("update-content-id")
        );
    }

    renderUpdateS3(){
        ReactDOM.render(
            <UpdateS3 
                uid={this.props.uid}
                username={this.props.username}
                changeState={this.props.changeState}
                title={this.props.title} 
                loginOut={this.props.loginOut}/>,
            document.getElementById("update-content-id")
        );
    }

    render(){
        return(
            <div id="update-content-id"></div>
        )
    }
}

class Method extends Component{
    render(){
        return(
            <div>
                <Row style={{ marginTop: "40px" }}>
                    <Col span={14} offset={5}>
                        <p style={{ fontSize:"15px", fontWeight:"bold" }}>为确保是您本人在操作，请您选择验证方式完成身份认证：</p>
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={12} offset={5}>
                        <label style={{ fontSize: "20px" }}>通过 手机验证身份</label>
                        <p>如果您的手机还在正常使用，可选择此方式。</p>
                    </Col>
                    <Col span={2} style={{ textAlign:"right" }}>
                        <Button 
                            block={true} 
                            type="primary" 
                            style={{ backgroundColor:"#EF6730",borderRadius:"0",border:"0",marginTop:"25px" }}
                            onClick={() =>{
                                this.props.changeMethod("phone");
                            }}>马上验证</Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={12} offset={5}>
                        <label style={{ fontSize: "20px" }}>通过 邮箱验证身份</label>
                        <p>如果您的邮箱还在正常使用，可选择此方式。</p>
                    </Col>
                    <Col span={2} style={{ textAlign:"right" }}>
                        <Button 
                            block={true} 
                            type="primary" 
                            style={{ backgroundColor:"#EF6730",borderRadius:"0",border:"0",marginTop:"25px" }}
                            onClick={() =>{
                                this.props.changeMethod("email");
                            }}>马上验证</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default class PfindEntity extends Component{
    constructor(props){
        super(props);
        this.state = {
            s: 1,
            s1: "process",
            s2: "wait",
            s3: "wait",
            method:""
        }
    }

    componentDidUpdate(){
        switch(this.state.method){
            case "phone":
                this.renderPhone();
                break;
            case "email":
                this.renderEmail();
                break;
            default:
                break;
        }
    }

    changeState = (s) =>{
        var s1 = "process";
        var s2 = "wait";
        var s3 = "wait";
        switch(s){
            case 2:
                s1 = "finish";
                s2 = "process";
                s3 = "wait";
                break;
            case 3:
                s1 = "finish";
                s2 = "finish";
                s3 = "process";
                break;
            default:
                break;
        }
        this.setState({ 
            s: s,
            s1: s1,
            s2: s2,
            s3: s3 
        });
    }

    changeMethod = (m) =>{
        this.setState({ method: m });
    }

    renderPhone(){
        ReactDOM.render(
            <Phone 
                username={this.props.username}
                s={this.state.s}
                changeState={this.changeState}
            />,
            document.getElementById("update-id")
        );
    }

    renderEmail(){
        ReactDOM.render(
            <Email 
                username={this.props.username}
                s={this.state.s}
                changeState={this.changeState}
            />,
            document.getElementById("update-id")
        );
    }

    renderMethod(){
        ReactDOM.render(
            <Method 
                changeMethod={this.changeMethod}
            />,
            document.getElementById("update-id")
        );
    }

    componentDidMount(){
        this.renderMethod();
    }

    render(){
        return(
            <div>
                <Row>
                    <Col span={14} offset={5} style={{ marginTop: "40px" }}>
                        <Steps>
                            <Step status={this.state.s1} title="验证身份" icon={<SolutionOutlined />} />
                            <Step status={this.state.s2} title="修改密码" icon={<EditOutlined />} />
                            <Step status={this.state.s3} title="修改完成" icon={<CheckOutlined />} />
                        </Steps>
                    </Col>
                </Row>
                <div id="update-id"></div>
            </div>
        );
    }
}