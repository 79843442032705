import { message } from "antd";
import CONFIG from "./Config";
/***
 * 浏览器宽度
 */
export const winWidth = UgetWinWidth();
function UgetWinWidth(){
    var winWidth  = 0;
    //  获取窗口宽度
    if (window.innerWidth){
        winWidth = window.innerWidth;
    }else if ((document.body) && (document.body.clientWidth)){
        winWidth = document.body.clientWidth;
    }
    return winWidth;
}


/***
 * 浏览器高度
 */
export const winHeight = UgetWinHeight();
function UgetWinHeight(){
    var winHeight = 0;
    //  获取窗口高度
    if (window.innerHeight){
        winHeight = window.innerHeight;
    }else if ((document.body) && (document.body.clientHeight)){
        winHeight = document.body.clientHeight;
    }
    return winHeight;
}

/***
 * 操作系统信息
 */
export const UOS = UgetOS();
function UgetOS(){
    var sUserAgent = navigator.userAgent;
    var isWin = (navigator.platform === "Win32") || (navigator.platform === "Windows");
    var isMac = (navigator.platform === "Mac68K") || (navigator.platform === "MacPPC") || (navigator.platform === "Macintosh") || (navigator.platform === "MacIntel");
    if (isMac) return "Mac";
    var isUnix = (navigator.platform === "X11") && !isWin && !isMac;
    if (isUnix) return "Unix";
    var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
    if (isLinux) return "Linux";
    if (isWin) {
        var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
        if (isWin2K) return "Win2000";
        var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
        if (isWinXP) return "WinXP";
        var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
        if (isWin2003) return "Win2003";
        var isWinVista= sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
        if (isWinVista) return "WinVista";
        var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
        if (isWin7) return "Win7";
        var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
        if (isWin10) return "Win10";
    }
    return "other";
}

/**
 * 
 * @param {*} img 
 * @param {*} callback 
 */
export function UgetBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}

/**
 * 会员头像大小检测
 * @param {*} file 
 * @returns 
 */
export function UmemberAvatarCheck(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("只支持 JPG/PNG 格式的图片!");
    }
    const isLt1M = file.size / 1024  < 100;
    if (!isLt1M) {
        message.error("图像必须小于 100KB!");
    }
    return isJpgOrPng && isLt1M;
}

/**
 * 连接资源路径
 * @param {*} obj 
 * @returns 
 */
 export function UjoinUri(obj){
    var uri = ""
    for (var i=0;i<obj.length; i++){
        uri += "/" + obj[i]
    }
    return uri
}

/**
 * 连接资源参数
 * @param {*} obj 
 * @returns 
 */
 export function UjoinParams(obj){
    var params = "?"
    for (var key in obj){
        params += key + "=" + obj[key] + "&"
    }
    return params.substr(0, params.length - 1)
}

/**
 * 日期格式化
 * @param {*} fmt 
 * @param {*} date 
 * @returns 
 */
 export function UdateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

/**
 * 获取字符串二进制代码
 * @param {*} str 
 * @returns 
 */
 export function UgetStrBinaryCode(str){
    let strBinarys = "";
    if (str.length < 1){
        return strBinarys;
    }else{
        for (let i=0; i<str.length; i++){
            //  获取字符的二进制
            let strBinary = str[i].charCodeAt().toString(2);
            let add0s = ""
            //  不足 8 位，在前面补 0
            for (let j=0; j<8 - strBinary.length; j++){
                add0s += "0";
            }
            strBinarys += add0s + str[i].charCodeAt().toString(2);
        }
    }
    return strBinarys;
}

/**
 * 加密字符串
 * @param {*} str 
 * @returns 
 */
 export function UencryptoStr(str){
    let strBinary = UgetStrBinaryCode(str);
    //  长度不是 6 的整数倍在后面补 0
    const add0s = 6 - (strBinary.length) % 6;
    for (let i=0; i<add0s; i++){
        strBinary += "0";
    }
    let encryptStr = "";
    for (let j=0; j<strBinary.length; j+=6){
        //  按 6 位截取
        let subStr = strBinary.substr(j, 6);
        //  二进制转十进制
        let bin2Int = parseInt(subStr, 2);
        encryptStr += CONFIG.encryptChars[bin2Int]
    }
    return encryptStr;
}