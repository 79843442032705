/***
*   配置文件
*   xianwen.zhang
*   2017-11-10
*/
const CONFIG = {
    "api": "http://api.console.umxwe.com/account/v1/",
    "sealApi": "http://api.console.umxwe.com/seal/v1/",
    "homeUrl": "http://home.console.umxwe.com",
    "homePage"  : "http://www.umxwe.com",
    "version": "v2.0.0",
    "timeout": 5000,
    "tDivisor":9999999999,
    "maxAge": 3600,
    "encryptChars": "AK2mBpw0xCqvDr1Ude5NXs3V+WtQ9RuE4FLnoM8ZabSTYcO7PfghiH-IJjk6lGyz"       
}

export default CONFIG;
