/***
*   全局通用枚举
*   xianwen.zhang
*   2017-11-10
*/

//  返回码
global.ENUM_RES = {
    'success': 0
}

//  cookie key
global.ENUM_COOKIE_KEY = {
    'account' : 'login_umxwe_id',
    'uid': 'login_umxwe_uid'
}