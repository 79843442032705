/*
*   登录组件
*/
import React , { Component } from "react";
import $ from "jquery";
import cookie from "react-cookies";
import "antd/dist/antd.css"; 
import {
    UserOutlined,
    InfoCircleOutlined,
    ClockCircleOutlined,
    GithubOutlined,
    WechatOutlined,
  } from "@ant-design/icons";
import { Input, Button, Row,Col,Tooltip,message,Alert } from "antd";
// import {createBrowserHistory} from "history";
import {UencryptoStr, winWidth, winHeight} from "../../include/FunHelper";
import CONFIG from "../../include/Config.js";
import "../../include/EnumHelpr.js";     
import BG0  from "../../images/bg.jpg";

// const history = createBrowserHistory();

//  登录背景块样式
var bgStyle = {
    width: winWidth,
    height: winHeight,
    background:`url(${BG0})`,
    backgroundSize:"100% 100%", 
    backgroundAttachment: "fixed",
    // backgroundColor: "#041d44",
    backgroundRepeat:"no-repeat"
};

export default class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            account: "",
            password: "",
            keepMe: false,
            loading: false,
            visible: false,
            msg: ""
        }
    }

    changeAccount = (e) =>{if(e.target.type !== "text"){return;}this.setState({account: e.target.value,isLoading: false,});}
    changePassword = (e) =>{if(e.target.type !== "password"){return;};this.setState({password: e.target.value,isLoading: false,});}
    changeKeepMe = (e) =>{if(e.target.type !== "checkbox"){return;};this.setState({keepMe: this.state.keepMe? false:true});}

    /***
     * 申请账户
     */
    addAccount(){message.info("程序员跑路啦······", 1);}

    /***
     * github 登录
     */
    github(){message.info("程序员跑路啦······", 1);}

    /***
     * wechat 登录
     */
    wechat(){message.info("程序员跑路啦······", 1);}

    /***
     * 即将登陆
     * 输入密码后回车绑定的事件
     */
    theLogin = (e) =>{if(e.target.type !== "password"){return;};this.login(e);}
    
    login = (e) =>{
        //  参数检查
        if(this.state.account === ""){
            message.error("用户名不能为空", 1);
            return;
        }
        if(this.state.password === ""){
            message.error("密码不能为空", 1);
            return;
        }
        const _this = this

        this.setState({
            loading: true,
            visible: false
        });
        
        //  登录
        $.ajax({
            url: CONFIG.api + "login",
            type: "POST",
            dataType: "JSON",
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            data:JSON.stringify({
                "account": this.state.account,
                "password": UencryptoStr(this.state.password)
            }),success:function(data){
                if(data.res === global.ENUM_RES.success){
                    window.location.href = CONFIG.homeUrl;
                }else{
                    _this.setState({
                        loading: false,
                        visible: true,
                        msg: data.msg
                    });
                }
            }
        })
    }

    isLogin(){
        if (cookie.load(global.ENUM_COOKIE_KEY.account) && cookie.load(global.ENUM_COOKIE_KEY.uid)){
            window.location.href = CONFIG.homeUrl;
        }
    }

    componentDidMount(){
        this.isLogin();
    }

    render(){
        return(
            <div style={bgStyle}>
                <Row style={{ color: "#FFFFFF",paddingTop: "150px"}}>
                    <Col span={11} offset={1} style={{ padding: "0 0 0 160px" }}>
                    {/* <img src={BG0} style={{ width:"150%",marginTop:"-68px" }} alt="MBR-第一扇区"/> */}
                        <h1 style={{ color:"#fff", fontSize: "65px"}}>构建开放合作的生态合作体系</h1>
                        <h1 style={{ color:"#fff"}}>为客户持续创造价值</h1>
                    </Col>
                    <Col span={7} offset={3}>
                        <div style={{ width: "400px",opacity: 1,padding: 30,backgroundColor: "#FFFFFF"}}>
                            <Row>
                                <h4>密码登录</h4>
                            </Row>
                            {
                                this.state.visible ? (
                                    <Row style={{ marginTop:"25px" }}>
                                        <Alert message={this.state.msg} type="warning" showIcon />
                                    </Row>
                                ):null
                            }
                            
                            <Row style={{ marginTop:"25px" }}>
                                <Input
                                    disabled={this.state.loading}
                                    value={this.state.account}
                                    onChange={this.changeAccount}
                                    size="large"
                                    placeholder="请输入登录账户"
                                    prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                    suffix={
                                    <Tooltip title="形如：zhangsan">
                                        <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                                    </Tooltip>
                                    }
                                />
                            </Row>
                            <Row style={{ marginTop:"20px" }}>
                                <Input.Password  
                                    disabled={this.state.loading}
                                    value={this.state.password}
                                    onChange={this.changePassword}
                                    onPressEnter={this.theLogin}
                                    size="large"
                                    placeholder="请输入登录密码"
                                    prefix={<ClockCircleOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                />
                            </Row>
                            <Row style={{ marginTop:"20px" }}>
                                <Button 
                                    style={{ borderRadius: "0"}}
                                    type="primary" 
                                    block 
                                    loading={this.state.loading} 
                                    onClick={this.login}
                                    size="large"
                                >
                                登录
                                </Button>
                            </Row>
                            <Row style={{ textAlign: "right",margin: "5px" }}>
                                <a style={{ marginRight: "5px" }} href={ "/pfind/t=" + Date.parse(new Date()) / CONFIG.tDivisor + "&u=true"} target="_blank" rel="noopener noreferrer">忘记密码?</a> 
                                <a href="/"
                                    title="申请账户"
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        this.addAccount();
                                }}>申请账户</a>
                            </Row>
                            <Row style={{ margin:"20px 0", color: "#000000" }}>
                                <label>其它登录方式：</label>
                                <a href="/"
                                    title="github"
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        this.github();
                                }}
                                ><GithubOutlined style={{ fontSize: "16px", color: "#000000", marginRight: "5px" }} /></a>
                                <a href="/"
                                    title="wechat"
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        this.wechat();
                                }}
                                ><WechatOutlined style={{ fontSize: "16px", color: "green" }} /></a>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}